<template>
  <LayoutGuest>
    <div class="container">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col">
          <div class="row mt-3">
            <div class="col-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4">
              <Notif />
              <br />
              <Form
                method="POST"
                @submit="register"
                :validation-schema="schema"
                v-slot="{ errors }"
                class="form-signin needs-validation"
              >
                <h1
                  class="
                    h5
                    mb-3
                    font-weight-normal
                    text-center text-uppercase
                    mb-4
                  "
                >
                  Daftar Akun
                </h1>

                <div class="form-group">
                  <label for="nama"
                    >Nama Lengkap: <span class="text-danger">*</span></label
                  >
                  <Field
                    type="text"
                    class="form-control"
                    id="name"
                    placeholder="Masukkan nama lengkap..."
                    name="name"
                    v-model="state.form.name"
                    :class="{ 'is-invalid': errors.name }"
                  />
                  <div class="valid-feedback">Valid.</div>
                  <div class="invalid-feedback">{{ errors.name }}</div>
                </div>
                <div class="form-group">
                  <label for="email"
                    >Email: <span class="text-danger">*</span></label
                  >
                  <Field
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Masukkan alamat email..."
                    name="email"
                    v-model="state.form.email"
                    :class="{ 'is-invalid': errors.email }"
                  />
                  <div class="valid-feedback">Valid.</div>
                  <div class="invalid-feedback">{{ errors.email }}</div>
                </div>
                <div class="form-group">
                  <label for="hp"
                    >Nomor HP: <span class="text-danger">*</span></label
                  >
                  <vue-tel-input
                    v-model="state.form.phone"
                    @input="phoneInput"
                    class="form-control"
                    id="phone"
                    name="phone"
                    :class="{ 'is-invalid': errors.phone }"
                  ></vue-tel-input>
                  <Field
                    type="hidden"
                    name="phone"
                    v-model="state.form.phone"
                  />
                  <div class="valid-feedback">Valid.</div>
                  <div class="invalid-feedback">{{ errors.phone }}</div>
                </div>
                <div class="form-group">
                  <label for="pass1"
                    >Password: <span class="text-danger">*</span></label
                  >
                  <Field
                    type="password"
                    class="form-control"
                    id="password"
                    name="password"
                    placeholder="Masukkan password..."
                    v-model="state.form.password"
                    :class="{ 'is-invalid': errors.password }"
                  />
                  <div class="valid-feedback">Valid.</div>
                  <div class="invalid-feedback">{{ errors.password }}</div>
                </div>
                <div class="form-group">
                  <label for="pass1"
                    >Konfirmasi Password:
                    <span class="text-danger">*</span></label
                  >
                  <Field
                    type="password"
                    class="form-control"
                    id="password_confirmation"
                    name="password_confirmation"
                    placeholder="Ulangi password..."
                    v-model="state.form.password_confirmation"
                    :class="{ 'is-invalid': errors.password_confirmation }"
                  />
                  <div class="valid-feedback">Valid.</div>
                  <div class="invalid-feedback">
                    {{ errors.password_confirmation }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="kode">Kode Promo:</label>
                  <Field
                    type="text"
                    class="form-control"
                    id="promo_code"
                    placeholder="Masukkan kode promo bila ada"
                    name="promo_code"
                    v-model="state.form.promo_code"
                    :class="{ 'is-invalid': errors.promo_code }"
                  />
                  <div class="valid-feedback">Valid.</div>
                  <div class="invalid-feedback">{{ errors.promo_code }}</div>
                </div>

                <button
                  class="btn btn-main btn-block mt-4 text-uppercase"
                  type="submit"
                  :disabled="state.loading"
                >
                  <span
                    class="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"
                    v-if="state.loading"
                  ></span>
                  Daftar
                </button>
                <router-link
                  to="/login"
                  class="btn btn-link btn-block mt-4"
                  title="Login"
                  >Sudah pernah mendaftar?</router-link
                >
              </Form>
            </div>
          </div>
        </div>
        <!-- #main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </LayoutGuest>
</template>
<script>
import { reactive, onMounted } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import Notif from "../../helpers/Notif.vue";
import { useVueSweetAlert2 } from "../../useVueSweetAlert2.js";
import LayoutGuest from "../../components/guest/Layout.vue";

export default {
  components: {
    Form,
    Field,
    Notif,
    LayoutGuest,
  },
  setup() {
    //validation
    const schema = Yup.object().shape({
      name: Yup.string().required("Nama tidak boleh kosong"),
      phone: Yup.string().required("Nomor Ponsel tidak boleh kosong"),
      email: Yup.string()
        .required("Email tidak boleh kosong")
        .email("Email harus berupa alamat surel"),
      password: Yup.string()
        .min(6, "Password sedikitnya mengandung 6 karakter.")
        .required("Password tidak boleh kosong"),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password harus sama")
        .required("Password tidak boleh kosong"),
    });
    const store = useStore();
    const router = useRouter();
    const $swal = useVueSweetAlert2();

    const state = reactive({
      form: {
        name: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
        promo_code: "",
      },
      loading: true,
    });
    onMounted(() => {
      store.dispatch("error/setDefault");

      //if login
      if (store.state.auth.authenticated) {
        if (store.state.auth.role === "seller") {
          router.push({ path: "/jual/pesanan-group" });
        } else if (store.state.auth.role === "admin") {
          router.push({ path: "/admin/dashboard" });
        }
      }
      state.loading = false;
    });
    const register = () => {
      state.loading = true;
      axios.post("/api/register", state.form).then(
        (res) => {
          if (res.data.success) {
            $swal.fire({
              icon: "success",
              title: "Selamat",
              text: "Pendaftaran Anda Telah di terima!",
            });
            router.push({ path: "/login" });
          }
          state.loading = false;
        },
        () => {
          state.loading = false;
        }
      );
    };
    return {
      state,
      schema,
      register,
    };
  },
  methods: {
    phoneInput(phone, phoneObject) {
      if (phoneObject?.formatted) {
        this.state.form.phone = phoneObject.formatted;
      }
    },
  },
};
</script>